import styled from "styled-components";

export const Headline = styled.h1`
  font-family: "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho,
    "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 67px;
  font-weight: 700;
  color: #fff;
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    span {
      display: block;
      transition: 1.6s ease 0.5s;
      opacity: 0;
      transform: translateY(100px);
    }
  }
  &.active p span {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 767px) {
    font-size: 7.8vw;
  }
`;

export const SlideImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: ${(props) =>
    props.number ? `url(img/bg0${props.number}.jpg)` : "none"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  opacity: 1;
  transition: 7s ease-out;
  transform: scale(1);
  backface-visibility: hidden;
  &.active {
    transform: scale(1.1);
  }
  &.intro {
    opacity: 0;
  }
  @media (max-width: 767px) {
    background-image: ${(props) =>
      props.number ? `url(img/bg0${props.number}_sp.jpg)` : "none"};
  }
`;

export const SlideImageIntro = styled(SlideImage)``;

export const SlideContent = styled.div`
  position: relative;
  z-index: 1;
  width: 90%;
  margin: auto;
  color: #fff;
`;

export const SlideContainer = styled.div`
  max-width: 760px;
`;

export const SlideText = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho,
    "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  text-decoration: underline;
  max-width: 560px;
  line-height: 2;
  transition: filter 1.6s ease 1.6s, opacity 2.6s ease 0.75s;
  transition-delay: 1.6s;
  opacity: 0;
  filter: blur(20px);
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    filter: blur(0);
  }
  @media (max-width: 767px) {
    font-size: 3.2vw;
    max-width: 300px;
    line-height: 2;
  }
`;

export const CopyText = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho,
    "Hiragino Mincho ProN", HGS明朝E, Meiryo, serif;

  @media (max-width: 767px) {
    margin: 12.5vh 0;
  }

  .sub {
    font-size: 25px;
    overflow: hidden;
    @media (max-width: 800px) {
      font-size: 22px;
    }
    @media (max-width: 767px) {
      font-size: 3.2vw;
    }

    span {
      display: block;
      transition: transform 1s ease 1s, opacity 0.5s ease 1.3s;
      transform: translateY(1.5em);
      opacity: 0;
    }
  }
  .main {
    font-size: 58px;
    overflow: hidden;
    @media (max-width: 800px) {
      font-size: 50px;
    }
    @media (max-width: 767px) {
      font-size: 6.6vw;
    }

    span {
      display: block;
      transition: transform 1.2s ease 1.2s, opacity 1s ease 2s;
      transition-delay: 1.2s;
      transform: translateY(1.5em);
      opacity: 0;
      backface-visibility: hidden;
    }
  }
  &.active {
    .sub span {
      transform: translateY(0);
      opacity: 1;
    }
    .main span {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const SlideNumber = styled.div`
  position: absolute;
  display: block;
  width: 1000px;
  height: 1000px;
  top: -280px;
  left: calc(50% - 280px);
  background-size: contain;
  background-repeat: no-repeat;
  transition: filter 1.6s ease 1.6s, opacity 2.6s ease 0.75s,
    transform 1.6s ease 2s;
  transition-delay: 1.6s;
  transform: scale(1.1);
  filter: blur(20px);
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  &.active {
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 767px) {
    width: 120vw;
    height: 100vw;
    top: -5.6vw;
    left: 12vw;
  }
`;

export const DiaList = styled.ul`
  list-style: none;
  display: flex;
  position: absolute;
  left: 0;
  bottom: 50px;
  padding: 0;
  width: 100%;
  justify-content: center;
  opacity: 0;
  transition: opacity 1.5s ease-in-out 2.4s;
  &.active {
    opacity: 1;
  }
  @media (max-width: 767px) {
    display: block;
  }
  @media (max-width: 600px) {
    position: static;
  }
  li {
    position: relative;
    border: 1px solid #fff;
    width: 30%;
    max-width: 320px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewX(-20deg);
    @media (max-width: 860px) {
      width: 100%;
      margin-bottom: 10px;
    }
    @media (max-width: 767px) {
      width: 90%;
      max-width: 280px;
      margin: 3vh auto;
    }
    @media (max-width: 414px) {
      width: 75%;
      height: 16vw;
    }
    a {
      display: block;
      color: #fff;
      transform: skewX(20deg);
      overflow: hidden;
      padding: 20px 10px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      @media (max-width: 414px) {
        font-size: 2.8vw;
        padding: 2vw;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
        display: block;
        border-left: 8px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        @media (max-width: 414px) {
          top: calc(50% - 4px);
          border-left: 4px solid #fff;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
  }
`;

export const RGBLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: screen;
    max-width: 100%;
    height: auto;
  }
`;
