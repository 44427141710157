import React, { Component } from "react";
// import ReactFullpage from '@fullpage/react-fullpage'
import { SectionsContainer } from "react-fullpage"; // https://github.com/subtirelumihail/react-fullpage  it's not original!
import TheHeader from "./TheHeader";
import Intro from "./slides/intro";
import Slide from "./slides/slide";
import End from "./slides/End";
// import blue from '@material-ui/core/colors/blue'; // https://material-ui.com/style/color/
import "./fullpage-overrides.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  render() {
    const options = {
      // licenseKey: 'FE28C6FA-8ED04D85-9CB16668-69C7DA86',
      sectionClassName: "section",
      activeClass: "active",
      anchors: [
        "home",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "last",
      ],
      scrollBar: false,
      navigation: true,
      verticalAlign: false,
      sectionPaddingTop: "50px",
      sectionPaddingBottom: "50px",
      arrowNavigation: false,
      scrollCallback: (states) =>
        this.setState({ current: states.activeSection }),
    };

    const { current } = this.state;

    return (
      <div id="app">
        <TheHeader />

        <SectionsContainer
          className="container"
          {...options}
          activeSection={current}
        >
          <Intro
            activeSection={current}
            number="0"
            isActive={current === 0 ? true : false}
          />
          <Slide
            activeSection={current}
            number="1"
            isActive={current === 1 ? true : false}
            headline="実力主義だから、\n褒め言葉にウソがない。"
            text="サンコーリサイクルでの仕事はどれもノルマはありません。ノルマがなく会社全体がひとつのチームとなって全員で協力して行っています。ノルマがなく縛られるものがない代わり、自分自身で努力して成長していけば、大きな仕事を任せられるチャンスが数多くあります。"
          />

          <Slide
            activeSection={current}
            number="2"
            isActive={current === 2 ? true : false}
            headline="好きな音楽が、\nやる気と仲間意識を\n高める。"
            text="外での現場作業ばかりではなく、社内での業務も多くあります。パソコンのキーボードを叩くカチカチ音や、電話応対の声だけが流れる空間よりも、かすかに流れるBGMで、緊張感の中にもリラックスした雰囲気を作り出すことができます。最新の高音質スピーカーから流れるBGMを同僚と共有しながら仲間意識を高めて、より効率的な仕事をすることができます。"
          />

          <Slide
            activeSection={current}
            number="3"
            isActive={current === 3 ? true : false}
            headline="社員旅行での\n海外経験が、\n広い視野を創り出す。"
            text="社員同士の絆を大切にするため、社員旅行は海外へ行くことがあります。日本の日常とは異なる刺激のある体験が、新しい企画や想像力を大きく働かせ、未来の可能性を見つけるきっかけになることもあります。毎日仕事ばかりに没頭する人生よりも、オンオフをしっかりとし、それをプライベートにもビジネスにも生かす。そんな贅沢な環境がサンコーリサイクルにはあります。"
          />

          <Slide
            activeSection={current}
            number="4"
            isActive={current === 4 ? true : false}
            headline="恋人とのデートが、\nタダになる。"
            text="いくつになっても利用して楽しい場所が動物園。サンコーリサイクルは、名古屋で一番古い動物園「東山動物園」のスポンサーをしています。スポンサーメリットのひとつとして定期的に入場券を頂けるので、社員はそれを恋人とのデートや家族サービスに使うことができます。プライベートも充実するために、会社からのささやかなプレゼントです。"
          />

          <Slide
            activeSection={current}
            number="5"
            isActive={current === 5 ? true : false}
            headline="もれなく、\n資格取得が付いてくる。"
            text="プラントオペレーターとして入社する方は誰もが経験者ではありません。未経験の方でも、必要な資格は入社してから会社サポートの上、取得することができるから安心です。また、仕事には直接関係のない資格でも、自己啓発、意欲向上、スキルアップとして会社から認められれば取得への支援もおこなっています。"
          />

          <Slide
            activeSection={current}
            number="6"
            isActive={current === 6 ? true : false}
            headline="誕生日は、\n毎年リゾートで\nセレブ気分。"
            text="サンコーリサイクルの福利厚生は、毎年の誕生日に会員制リゾートクラブへの宿泊をプレゼントしたりと、他にはないほど充実しています。働く人を労い、応援する会社。サンコーリサイクルは、その気持ちをカタチにする会社です。"
          />

          <Slide
            activeSection={current}
            number="7"
            isActive={current === 7 ? true : false}
            headline="職場が快適すぎて\n自分の部屋よりも\n居心地がイイ。"
            text="汚染土壌のお仕事なので、一見、泥臭い社内と思われますが、だからこそ、職場は常に清潔に保っています。木目をふんだんに使ったインテリアは、暖かい雰囲気の空間で心地よく働く事ができます。仕事が夜遅くなる時には夜食が大量に保管してあるので、小腹が空いて集中力を切らすこともありません。"
          />

          <Slide
            activeSection={current}
            number="8"
            isActive={current === 8 ? true : false}
            headline="上司も社長も同僚も、\n全員がチームメイト。\nいや、それ以上。"
            text="上司との距離が近い。それがサンコーリサイクルの一番の特長かもしれません。どの職種でも毎日必ず上司と顔を合わせ、仕事の相談はもちろん、プライベートの相談事まで、なんでも気軽に話すことができる関係性があります。また、任された仕事をひとりだけで遂行するのではなく、上司、同僚、他部署と連携を図りながら全員で利益確保を目標とし、チームとして一緒に成長していきます。"
          />

          <Slide
            activeSection={current}
            number="9"
            isActive={current === 9 ? true : false}
            headline="いつか結婚しても、\n奥さんや子供に\n叱られなくて済む。"
            text="サンコーリサイクルは、社員だけではなく、その家族や友人とのつながりも大切にした働き方を推奨しています。有給取得や育児休暇制度等はもちろん完備し、完全週休二日制で月の平均残業時間も10時間程度なので、仕事が忙しくプライベートな時間が取れないと言う事はありません。休日の予定をしっかりと立て楽しむことも、人としてのスキルアップにつながると考えています。"
          />

          <Slide
            activeSection={current}
            number="10"
            isActive={current === 10 ? true : false}
            headline="日本の未来を支えている。\nそう実感できる。"
            text="インフラ整備や土地開発など、わたしたちの生活を豊かにするためには、どうしても多くの廃棄物が発生してしまいます。中には汚染物質を含むものもあり社会問題になっています。発生した大量の廃棄物をしっかりと処理する役割を担い、日本の都市開発を陰で支える。わたしたちは縁の下の力持ちとして活躍し、これからもビジネスを発展させていきます。"
          />
          <End
            activeSection={current}
            number="11"
            isActive={current === 11 ? true : false}
          />
        </SectionsContainer>
      </div>
    );
  }
}

export default App;
