import React, { Component } from "react";
import { Section } from "react-fullpage";
import { CopyText, DiaList } from "./style";
import rikunavi from "../../rikunavi.png";

class End extends Component {
  render() {
    return (
      <Section className="custom-section" verticalAlign="true" color="#000000">
        <div className="container" style={{ width: "70%", margin: "auto" }}>
          <CopyText className={this.props.isActive ? "active" : ""}>
            <div className="sub">
              <span>キミの笑顔と成長が、綺麗な未来を創り出す。</span>
            </div>
            <div className="main">
              <span>さぁ、一緒に働こう。</span>
            </div>
          </CopyText>
          {/* <DiaList className={this.props.isActive ? 'active' : ''}>
            <li style={{backgroundColor: '#FFFFFF'}}><a href="https://job.rikunabi.com/2020/company/r699522000/" target="_blank" rel="noopener noreferrer"><img src={rikunavi} alt="リクナビ2020" /></a></li>
            <li><a href="https://employment.en-japan.com/desc_864008/?aroute=6&arearoute=1&PK=EAFA57" target="_blank" rel="noopener noreferrer">ドライバーの中途採用</a></li>
            <li><a href="https://employment.en-japan.com/desc_863453/?aroute=6&arearoute=1&PK=EAFA57" target="_blank" rel="noopener noreferrer">プラントオペレーターの中途採用</a></li>
          </DiaList> */}
        </div>
      </Section>
    );
  }
}

export default End;
