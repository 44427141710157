import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import { Header } from "react-fullpage";
import Logo from "../logo.png";

class TheHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      playing: false,
      BGMLabel: "再生",
      IEUser: 0,
    };
  }

  setMounted() {
    this.setState({ isMounted: true });
  }

  componentDidMount() {
    // UA
    let userAgent = window.navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("msie") !== -1 ||
      userAgent.indexOf("trident") !== -1
    ) {
      this.setState({ IEUser: 1 });
      return;
    }

    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AudioContext();

    const getAudioBuffer = function (url, fn) {
      const req = new XMLHttpRequest();
      // array buffer を指定
      req.responseType = "arraybuffer";

      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status === 0 || req.status === 200) {
            // array buffer を audio buffer に変換
            context.decodeAudioData(req.response, function (buffer) {
              // コールバックを実行
              fn(buffer);
            });
          }
        }
      };

      req.open("GET", url, true);
      req.send("");
    };

    let soundFile;

    // マウント完了を伝える
    setTimeout(() => {
      this.setMounted();
    }, 1800);

    // サウンドを再生
    const playSound = (buffer) => {
      // source を作成
      const source = context.createBufferSource();
      // buffer をセット
      source.buffer = buffer;
      // context に connect
      source.connect(context.destination);
      // 外部の関数からはsoundFileで処理を行う
      soundFile = source;
      // 再生
      source.start(0);
      this.setState({ playing: true });
    };

    const stopSound = () => {
      if (soundFile != null) {
        soundFile.stop();
      }
      this.setState({ playing: false });
    };

    // main
    window.onload = () => {
      let playing = false;

      // サウンドを読み込む
      getAudioBuffer("sound.mp3", function (buffer) {
        // 読み込み完了後にボタンにクリックイベントを登録
        // const btn = document.getElementById('btn')
        // btn.onclick = function() {
        // this.state.playing で直接参照できなかったので、
        // onclickのthisに相当するbtnの属性から、再生状態を判断する
        // 属性の値は文字列型になっている false ではなく 'false'
        // if ( this.getAttribute('data-bgm-state') === 'false' ) {
        const myswicth = document.getElementById("switch");

        myswicth.onclick = function () {
          if (playing === false) {
            // サウンドを再生
            playSound(buffer);
            playing = true;
          } else {
            stopSound();
            playing = false;
          }
        };
      });
    };
  }

  render() {
    return (
      <Header>
        <h1>
          <a href="#home">
            <img src={Logo} className="header-logo" alt="logo" />
          </a>
        </h1>
        {this.state.IEUser === 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="label">SOUND</span>
            <Switch
              id="switch"
              color="primary"
              disabled={!this.state.isMounted}
            />
          </div>
        )}
      </Header>
    );
  }
}

export default TheHeader;
