import React, { Component } from "react";
import { Section } from "react-fullpage";
import title from "../../title.png";
import classNames from "classnames";

import { SlideImageIntro } from "./style";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      introActive: false,
    };
  }

  componentDidMount() {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        this.setMounted();
        resolve();
      }, 100);
    });
    promise.then(() => {
      setTimeout(() => {
        this.setActive();
      }, 500);
    });
  }

  setMounted() {
    this.setState({ isMounted: true });
  }

  setActive() {
    this.setState({ introActive: true });
  }

  render() {
    const animekeyClass = classNames({
      mounted: this.state.isMounted,
      active: this.state.introActive && this.props.isActive,
    });
    return (
      <Section
        id="intro-section"
        className="custom-section"
        verticalAlign="true"
      >
        <SlideImageIntro
          number="0"
          className={
            this.state.introActive && this.props.isActive ? "active" : ""
          }
        />
        <div className="container" style={{ width: "70%" }}>
          <h1 className={animekeyClass} style={{ position: "relative" }}>
            <img
              src={title}
              alt="わたしたちがここで働く10の理由"
              className="intro-title"
            />
          </h1>
        </div>
      </Section>
    );
  }
}

export default Intro;
