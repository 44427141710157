import React, { Component } from "react";
import { Section } from "react-fullpage";
// styled components
import {
  Headline,
  SlideImage,
  SlideContent,
  SlideContainer,
  SlideText,
  SlideNumber,
} from "./style";

class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: Number(this.props.number),
      headline: this.props.headline,
    };
  }

  render() {
    let headline = this.state.headline.split("\\n").map((m, i) => {
      return (
        <p key={i}>
          <span>{m}</span>
        </p>
      );
    });

    return (
      <Section className="custom-section" verticalAlign="true">
        <SlideImage
          number={this.state.number}
          className={this.props.isActive ? "active" : ""}
        />
        <div className="section-number" data-number={this.state.number} />
        <SlideContent>
          <SlideContainer>
            <Headline className={this.props.isActive ? "active" : ""}>
              {headline}
            </Headline>
            <SlideText className={this.props.isActive ? "active" : ""}>
              {this.props.text}
            </SlideText>
          </SlideContainer>
          <SlideNumber
            style={{
              backgroundImage: `url(img/numbers/${this.state.number}.png)`,
            }}
            className={this.props.isActive ? "active" : ""}
          />
        </SlideContent>
      </Section>
    );
  }
}

export default Slide;
